import { AlertController, NavController } from '@ionic/angular';
import { CartItemClass } from './../../common/common';
import { MyEvent } from './../../services/myevent.services';
import { CommonService } from './../../services/common.service';
import { PreferenceService } from './../../services/preference.service';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit {

  @Input() cart: CartItemClass;
  @Output() eventEmit = new EventEmitter();

  quantity = 0;

  constructor(
    public preference: PreferenceService,
    private comService: CommonService,
    private myEvent: MyEvent,
    private changeDetect: ChangeDetectorRef,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private translateService: TranslationService
  ) { }

  ngOnInit() {
    this.myEvent.getObservable().subscribe(data => {
      if (data.event == 'update_cart') {
        this.ngAfterViewInit();
      }
    });
  }

  ngAfterViewInit() {
    if (this.cart.items.length) {
      this.quantity = this.cart.items[0].qty;
    } else {
      this.quantity = this.cart.qty;
    }
  }

  showDetail() {
    this.navCtrl.navigateForward(`product-detail/${this.cart.id}`);
  }

  plus() {
    this.quantity++;
    this.calcTotalQty();
  }

  minute() {
    if (this.quantity > 0) {
      this.quantity--;
    }
    this.calcTotalQty();
  }

  calcTotalQty() {
    if (this.cart.items.length) {
      this.cart.items[0].qty = this.quantity;
      this.cart.qty = this.quantity;
    } else {
      this.cart.qty = this.quantity;
    }

    if (this.cart.qty === 0) {
      this.eventEmit.emit({event: 'remove', prodId: this.cart.id});
    }

    this.changeDetect.detectChanges();
    this.myEvent.publishSomeData({event: 'update_cart'});
  }

  async removeFromCart() {
    const warning = await this.translateService.getTranslationWord('warning');
    const yestxt = await this.translateService.getTranslationWord('yes');
    const notxt = await this.translateService.getTranslationWord('no');
    const sure_remove_item = await this.translateService.getTranslationWord('sure_remove_item');

    this.alertCtrl.create({
      header: warning,
      message: sure_remove_item,
      buttons: [
        {
          text: notxt,
          role: 'cancel'
        },
        {
          text: yestxt,
          handler: () => {
            this.eventEmit.emit({event: 'remove', prodId: this.cart.id});
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

}
