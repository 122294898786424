import { CustomFooterComponent } from './custom-footer/custom-footer.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomHeaderComponent } from './custom-header/custom-header.component';


@NgModule({
  declarations: [
    ProductItemComponent,
    CartItemComponent,
    CustomFooterComponent,
    CustomHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    ProductItemComponent,
    CartItemComponent,
    CustomFooterComponent,
    CustomHeaderComponent
  ],
  entryComponents: [
    ProductItemComponent,
    CartItemComponent,
    CustomFooterComponent,
    CustomHeaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {

}
