import { MyEvent } from './services/myevent.services';
import { ApiService } from './services/api.service';
import { ACCESS_TOKEN, ACCESS_TOKEN_TYPE, THEME_TYPE, USER_LANG } from './common/common';
import { PreferenceService } from './services/preference.service';
import { CommonService } from './services/common.service';
import { Component, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonRouterOutlet, ModalController, Platform, NavController, MenuController } from '@ionic/angular';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import * as $ from "jquery";
import { locale as enLang } from '../assets/i18n/en';
import { TranslationService } from './services/translation.service';

const LOCALIZATION_LOCAL_STORAGE_KEY = 'my_lang';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  pageLoaded = false;

  theme = 'light';

  constructor(
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private platform: Platform,
    private modalCtrl: ModalController,
    private comService: CommonService,
    public preference: PreferenceService,
    private navCtrl: NavController,
    private api: ApiService,
    private router: Router,
    private myEvent: MyEvent,
    private changeDetect: ChangeDetectorRef,
    private keyboard: Keyboard,
    private menuCtrl: MenuController,
    private translationService: TranslationService
  ) {

    this.translationService.loadTranslations(
      enLang
    );

    this.init();

    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {

      } else if (events instanceof NavigationError) {
        window.location.href = '/';
      }
    });

    this.myEvent.getLanguageObservable().subscribe(value => {
      if (value) {
        this.translationService.setLanguage(value);
      }
      this.changeDetect.detectChanges();
    });
  }

  init() {
    this.platform.ready().then(() => {

      console.log("platforms =", this.platform.platforms());

      this.statusBar.overlaysWebView(false);

      const defaultLang = localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) || 'en';
      this.translationService.setSessionLang(defaultLang);

      if (this.platform.is('hybrid')) {
        this.comService.manageNotification();
      }

      if (localStorage.getItem(THEME_TYPE)) {
        this.theme = localStorage.getItem(THEME_TYPE);
      }

      if (localStorage.getItem(ACCESS_TOKEN)) {
        this.api.apiGetFunction('users/me').then(resp => {
          console.log('current user == ', resp);

          // this.preference.setCurrentUser(resp);

          // if (!resp.is_verified) {

          //   this.preference.setCurrentUser(resp);
          //   this.navCtrl.navigateRoot('request-verify');

          // } else
          if (!resp.is_active) {

            this.preference.setCurrentUser(null);
            localStorage.removeItem(ACCESS_TOKEN_TYPE);
            localStorage.removeItem(ACCESS_TOKEN);
            this.comService.showToast('Your acccount is not active yet', 'error_toast');
            this.navCtrl.navigateRoot('login');

          } else {

            this.preference.setCurrentUser(resp);

          }

          this.changeDetect.detectChanges();

        }).catch(err => {
          console.error('user error == ', err);
          this.preference.setCurrentUser(null);
          localStorage.removeItem(ACCESS_TOKEN_TYPE);
          localStorage.removeItem(ACCESS_TOKEN);
          this.comService.showToast('Your session is expired. Please login again', 'error_toast');
          this.navCtrl.navigateRoot('login');
          this.changeDetect.detectChanges();
        });
      } else {
        // this.navCtrl.navigateRoot('login');
        // this.navCtrl.navigateRoot('packages');
      }

      setTimeout(() => {
        this.pageLoaded = true;
        this.api.getCoutries();
      }, 2000);

      this.platform.backButton.subscribe(() => {
        this.exitApp();
      });

      this.splashScreen.hide();
    });
  }

  showSetting() {
    this.navCtrl.navigateForward('change-password');
    this.menuCtrl.close();
  }

  close() {
    this.menuCtrl.close();
  }

  changeTheme($event) {
    this.theme = $event.detail.value;
    localStorage.setItem(THEME_TYPE, this.theme);
    this.myEvent.publishSomeData({event: 'change:theme', theme: this.theme});
  }

  logout() {
    this.comService.showAlert('Are you sure to logout?', () => {
      this.preference.setCurrentUser(null);
      localStorage.removeItem(ACCESS_TOKEN_TYPE);
      localStorage.removeItem(ACCESS_TOKEN);
      this.navCtrl.navigateRoot('login', {animated: true, animationDirection: 'back'});
    });
  }

  help() {
    this.api.showLinkUrl('help url');
  }

  ///////
  async exitApp() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      await modal.dismiss();
    } else {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          // outlet.pop();
        } else {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            navigator['app'].exitApp();
          } else {
            const press_back_exit = await this.translationService.getTranslationWord('press_back_exit');
            this.comService.showToast(press_back_exit, 'error_toast');
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    }
  }

}
