import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'startup',
    loadChildren: () => import('./pages/startup/startup.module').then( m => m.StartupPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authorizations/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./authorizations/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot-pass',
    loadChildren: () => import('./authorizations/forgot-pass/forgot-pass.module').then( m => m.ForgotPassPageModule)
  },
  {
    path: 'packages',
    loadChildren: () => import('./pages/packages/packages.module').then( m => m.PackagesPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./authorizations/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'detail/:id',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'add-new',
    loadChildren: () => import('./pages/add-new/add-new.module').then( m => m.AddNewPageModule)
  },
  {
    path: 'email-verify',
    loadChildren: () => import('./pages/email-verify/email-verify.module').then( m => m.EmailVerifyPageModule)
  },
  {
    path: 'request-verify',
    loadChildren: () => import('./pages/request-verify/request-verify.module').then( m => m.RequestVerifyPageModule)
  },
  {
    path: 'add-new-twitter',
    loadChildren: () => import('./pages/add-new-twitter/add-new-twitter.module').then( m => m.AddNewTwitterPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
