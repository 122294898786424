import { Observable } from 'rxjs';
import { CommonService } from './../../services/common.service';
import { NavController } from '@ionic/angular';
import { PreferenceService } from './../../services/preference.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MyEvent } from 'src/app/services/myevent.services';

@Component({
  selector: 'app-custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss'],
})
export class CustomFooterComponent implements OnInit {

  newNoti: Observable<any>;
  newCount = 0;

  constructor(
    private myEvent: MyEvent,
    public preference: PreferenceService,
    private changeDetect: ChangeDetectorRef,
    private navCtrl: NavController,
    private comService: CommonService
  ) { 
    this.myEvent.getObservable().subscribe(data => {
      if (data.event === 'update_cart') {
        this.changeDetect.detectChanges();
      }
    });

    this.newNoti = this.comService.notificationSub.asObservable();
    this.newNoti.subscribe(value => {
      this.newCount = value;
    });
  }

  ngOnInit() {}

  goTab(tab) {
    if (tab === 'profile') {
      if (!this.preference.currentUser) {
        this.navCtrl.navigateForward('login');
      } else {
        this.navCtrl.navigateForward('tabs/profile');
      }
    } else {
      this.navCtrl.navigateForward('tabs/' + tab);
    }
  }

}
