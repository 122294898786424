import { PreferenceService } from './preference.service';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController, NavController, Platform, ActionSheetController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import OneSignal from 'onesignal-cordova-plugin';
// import { OneSignal, OSNotification, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { PUSH_TOKEN } from '../common/common';
// import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  currentDataEntryModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onKeyboardStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get currentDataEntryMode(): any { return this.currentDataEntryModeSubject.value; }
  public get isKeyboardShow(): any { return this.onKeyboardStatusSubject.value; }

  notificationSub: BehaviorSubject<any> = new BehaviorSubject<any>({});

  loginEmail: any;

  constructor(
    private toastCtrl: ToastController,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    public preference: PreferenceService,
    private navCtrl: NavController,
    // private onesignal: OneSignal,
    // private firebaseX: FirebaseX,
    private nativeStorage: NativeStorage,
    private storage: Storage,
    private platform: Platform,
    private actionSheet: ActionSheetController
  ) { }

  getItem(key): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is('hybrid')) {
        this.nativeStorage.getItem(key).then(data => resolve(data)).catch(err => reject(err));
      } else {
        this.storage.get(key).then(data => resolve(data)).catch(err => reject(err));
      }
    });
  }

  setItem(key, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is('hybrid')) {
        this.nativeStorage.setItem(key, data).then(data => resolve(data)).catch(err => reject(err));
      } else {
        this.storage.set(key, data).then(data => resolve(data)).catch(err => reject(err));
      }
    });
  }

  remove(key): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is('hybrid')) {
        this.nativeStorage.remove(key).then(data => resolve(data)).catch(err => reject(err));
      } else {
        this.storage.remove(key).then(data => resolve(data)).catch(err => reject(err));
      }
    });
  }

  clear(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is('hybrid')) {
        this.nativeStorage.clear().then(data => resolve(data)).catch(err => reject(err));
      } else {
        this.storage.clear().then(() => resolve(null)).catch(err => reject(err));
      }
    });
  }

  blurInput() {
    this.currentDataEntryModeSubject.next(false);
  }

  focusInput() {
    this.currentDataEntryModeSubject.next(true);
  }

  addNewChat() {
    this.actionSheet.create({
      header: 'Choose Chat Type',
      mode: 'ios',
      buttons: [
        {
          text: "Youtube",
          icon: 'logo-youtube',
          handler: () => {
            this.navCtrl.navigateForward('add-new');
          }
        },
        {
          text: 'Twitter',
          icon: 'logo-twitter',
          handler: () => {
            this.navCtrl.navigateForward('add-new-twitter');
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    }).then(action => action.present());
  }


  showToast(msg, class_name = 'success_toast') {
    this.toastCtrl.create({
      message: msg,
      cssClass: class_name,
      duration: 2500,
      position: 'top'
    }).then(res => res.present());
  }

  showAlert(msg: any, okCallback?: any) {
    var buttons: any[] = [];
    if (okCallback) {
      buttons = [
        {
          text: "Cancel",
          role: 'cancel'
        },
        {
          text: "OK",
          handler: () => okCallback()
        }
      ];
    }
    else {
      buttons = ["OK"];
    }
    this.alertCtrl.create({
      message: msg,
      buttons
    }).then(res => res.present());
  }

  async showLoader(msg = '') {
    const check = await this.loadCtrl.getTop();
    if (check) {
      check.dismiss();
    }
    this.loadCtrl.create({
      spinner: null,
      message: `<img src="assets/images/loading.gif" class="loading_gif">`,
      duration: 59500
    }).then(res => {
      res.present();
    });
  }

  async hideLoader() {
    this.loadCtrl.getTop().then(loading => {
      if (loading) {
        loading.dismiss();
      }
    });
    this.loadCtrl.dismiss();
  }

  checkLogin(): boolean {
    if (!this.preference.currentUser) {
      this.alertCtrl.create({
        header: 'Warning !',
        message: "You must login to use this feature. Will you login now?",
        buttons: [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.navCtrl.navigateForward('login');
            }
          }
        ]
      }).then(alert => {
        alert.present();
      });
      return false;
    } else {
      return true;
    }
  }


  calcCrow(lat1, lon1, lat2, lon2) {
    const R = 6371; // km, mile = 3958.754641
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d;
  }
  toRad(value) {
    return value * Math.PI / 180;
  }


  manageNotification() {
    // this.onesignal.startInit('23386478-0e7f-41f1-aec6-29a0d5fb145a', '877428495158');
    // this.onesignal.getTags().then((value) => {
    //   console.log('Tags Received: ' + JSON.stringify(value));
    // });

    // this.onesignal.getIds().then(data => {
    //   console.log('Onesignal Playerid:= ' + data.userId);//  device id
    //   this.preference.pushToken = data.userId;
    //   localStorage.setItem(ONESIGNAL_TOKEN, data.userId);
    // });
    // this.onesignal.inFocusDisplaying(this.onesignal.OSInFocusDisplayOption.Notification);

    // this.onesignal.handleNotificationReceived().subscribe((data: OSNotification) => {
    //   // do something when notification is received
    //   console.log('receive noti => ', data);
    //   this.notificationSub.next(1);
    // });

    // this.onesignal.handleNotificationOpened().subscribe((data: OSNotificationOpenedResult) => {
    //   // do something when a notification is opened
    //   console.log('open noti => ', data);
    //   this.navCtrl.navigateForward('/notifications');
    // });

    // this.onesignal.endInit();


    const self = this;
    OneSignal.setAppId('23386478-0e7f-41f1-aec6-29a0d5fb145a');
    OneSignal.setNotificationWillShowInForegroundHandler((event: any) => {
      event.getNotification();
      this.notificationSub.next(1);
    });
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      self.navCtrl.navigateForward('/notifications');
    });
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log('User accepted notifications: ' + accepted);
    });
    OneSignal.getDeviceState((state) => {
      console.log('Onesignal Playerid:= ' + state.userId);//  device id
      if (state && state.userId) {
        this.preference.pushToken = state.userId;
        localStorage.setItem(PUSH_TOKEN, state.userId);
      }
    });
  }

  sendTagToOnesignal(value = '') {
    OneSignal.sendTag("user_application_id", value);
    // this.onesignal.sendTag("user_application_id", value);
  }

  enableDisableNoti(enable = false) {
    // this.onesignal.setSubscription(enable);
    OneSignal.disablePush(!enable);
  }


  /**
   * Youtube info
   * @param url
   * @returns
   */
  getVideoId(url) {
    if (!url) {
      return '';
    }
    if (!url.includes('youtu')) return '';

    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      var id = match[2];
      return id;
    } else {
      return '';
    }

    // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    // var match = url.match(regExp);
    // return (match&&match[7].length==11)? match[7] : false;
  }

  getThumbOfYoutube = (videoId) => {
    return `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
  };

  async getTitle(videoId) {
    let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&fields=items(id%2Csnippet)&key=AIzaSyCJdmlKjQO7ec4QeMepMoYrf4LVJi-FBDo`);
    let data = await response.json();
    if (data.items && data.items.length) {
      return data.items[0].snippet.title;
    } else {
      return '';
    }
  }
  //////////////////////////////

}
