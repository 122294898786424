import { USER_LANG, USER_DATA, ACCESS_TOKEN, ACCESS_TOKEN_TYPE } from './../common/common';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';
import { PreferenceService } from './preference.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { NavController, Platform } from '@ionic/angular';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { base_api_url } from '../common/common';

// import firebase from 'firebase/app';
// import 'firebase/auth';


const headers = {
  // 'Content-Type': 'application/json', // 'multipart/form-data',
  'Accept': 'application/json'
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private nativeHttp: HTTP,
    private platform: Platform,
    private iab: InAppBrowser,
    public preference: PreferenceService,
    private comService: CommonService,
    private navCtrl: NavController
  ) { }

  getCoutries() {
    // this.apiGetFunction('settings.php').then(result => {
    //   this.preference.countries = result.countries;
    // });
  }

  objectToFormData(obj: any, form?: any, namespace?: any) {
    const fd: any = form || new FormData();
    let formKey: any;
    for (const property in obj) {
      if (obj.hasOwnProperty(property) && obj[property]) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        }
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          this.objectToFormData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  };

  apiGetFunction(api_name: string, param?: any): Promise<any> {
    const api_url = `${base_api_url}/${api_name}`;

    return new Promise((resolve, reject) => {

      if (this.platform.is('hybrid')) {

        if (localStorage.getItem(ACCESS_TOKEN)) {
          headers['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        } else {
          delete headers['Authorization'];
        }

        this.nativeHttp.get(api_url, {}, headers).then(result => {
          resolve(JSON.parse(result.data));
        }).catch(error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            this.showError('Error received from the backend api');
          }
          reject(error);
        });

      } else {

        var bHeaders: any = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }

        if (localStorage.getItem(ACCESS_TOKEN)) {
          bHeaders['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        }

        const heads = new HttpHeaders(bHeaders);

        const httpOptions = {
          headers: heads
        };

        this.http.get(api_url, httpOptions).subscribe(result => {
          resolve(result);
        }, error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            this.showError('Error received from the backend api');
          }
          reject(error);
        });

      }

    });
  }

  apiPostFunction(api_name, data, dataType = 'json', showErrorToast = true): Promise<any> {
    const api_url = `${base_api_url}/${api_name}`;
    console.log('api name == ', api_url, data);

    let params: any = {};
    if (data) params = data;

    return new Promise((resolve, reject) => {

      if (this.platform.is('hybrid')) {

        if (localStorage.getItem(ACCESS_TOKEN)) {
          headers['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        } else {
          delete headers['Authorization'];
        }

        let sendData: any = {};

        if (dataType == 'json') {
          sendData = params;
          this.nativeHttp.setDataSerializer('json');
        }
        else if (dataType == 'multipart') {
          sendData = this.objectToFormData(params);
          this.nativeHttp.setDataSerializer('multipart');
        }

        this.nativeHttp.post(api_url, sendData, headers).then(result => {
          resolve(JSON.parse(result.data));
        }).catch(error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            if (showErrorToast) this.showError('Error received from the backend api');
          }
          reject(error);
        });

      } else {
       
        var bHeaders: any = {
          'Accept': 'application/json'
        }

        let sendData: any = {};
        if (dataType == 'multipart') {
          sendData = this.objectToFormData(params);
        } else if (dataType == 'json') {
          sendData = params;
        }

        if (localStorage.getItem(ACCESS_TOKEN)) {
          bHeaders['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        }

        const heads = new HttpHeaders(bHeaders);

        const httpOptions = {
          headers: heads
        };

        this.http.post(api_url, sendData, httpOptions).subscribe(result => {
          resolve(result);
        }, error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            if (showErrorToast) this.showError('Error received from the backend api');
          }
          reject(error);
        });

      }
    });
  }


  apiDeleteFunction(api_name, data, dataType?, showErrorToast = true): Promise<any> {
    const api_url = `${base_api_url}/${api_name}`;
    console.log('api name == ', api_url, data);

    let params: any = {};
    if (data) params = data;

    return new Promise((resolve, reject) => {

      if (this.platform.is('hybrid')) {

        if (localStorage.getItem(ACCESS_TOKEN)) {
          headers['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        } else {
          delete headers['Authorization'];
        }

        let sendData: any = {};

        if (dataType == 'json') {
          sendData = params;
          this.nativeHttp.setDataSerializer('json');
        }
        else if (dataType == 'multipart') {
          sendData = this.objectToFormData(params);
          this.nativeHttp.setDataSerializer('multipart');
        }

        this.nativeHttp.delete(api_url, sendData, headers).then(result => {
          resolve(JSON.parse(result.data));
        }).catch(error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            if (showErrorToast) this.showError('Error received from the backend api');
          }
          reject(error);
        });

      } else {
        
        var bHeaders: any = {
          'Accept': 'application/json'
        }

        let sendData: any = {};
        if (dataType == 'multipart') {
          sendData = this.objectToFormData(params);
        } else if (dataType == 'json') {
          sendData = params;
        }

        if (localStorage.getItem(ACCESS_TOKEN)) {
          bHeaders['Authorization'] = localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN);
        }

        const heads = new HttpHeaders(bHeaders);

        const httpOptions = {
          headers: heads
        };

        this.http.delete(api_url, httpOptions).subscribe(result => {
          resolve(result);
        }, error => {
          if (error.status == 401) {
            this.showError('You have to login again');
            this.navCtrl.navigateRoot('login');
          } else {
            if (showErrorToast) this.showError('Error received from the backend api');
          }
          reject(error);
        });

      }
    });
  }

  showError(msg) {
    this.comService.showToast(msg, 'error_toast');
  }

  /**
   *
   * @param api_name
   * @param file
   * @param data
   * @param fileName
   * @param fileKey
   * @returns
   */
  uploadMedia1(api_name, file, data, fileName, fileKey): Promise<any> {
    const lang = localStorage.getItem(USER_LANG) || 'pt';
    const api_url = `${base_api_url}/${lang}/mobile/${api_name}`;

    let params: any = {};
    params = data;
    if (localStorage.getItem(USER_DATA)) {
      params['user_id'] = this.preference.currentUser ? this.preference.currentUser.id : 0;
    }

    return new Promise((resolve, reject) => {

      if (this.platform.is('hybrid')) {

        this.nativeHttp.setDataSerializer('multipart');

        let form = new FormData();
        Object.keys(params).forEach(key => {
          form.append(key, params[key]);
        });
        form.append(fileKey, file, fileName);

        console.log('request == ', form);

        this.nativeHttp.post(api_url, form, headers).then(result => {
          resolve(JSON.parse(result.data));
        }).catch(error => reject(error));

      } else {

        let form = new FormData();
        Object.keys(params).forEach(key => {
          form.append(key, params[key]);
        });
        form.append(fileKey, file, fileName);

        const heads = new HttpHeaders();
        heads.append('Accept', 'application/json');
        if (localStorage.getItem(ACCESS_TOKEN)) {
          heads.append('Authorization', localStorage.getItem(ACCESS_TOKEN_TYPE) + ' ' + localStorage.getItem(ACCESS_TOKEN));
        }
        const httpOptions = {
          headers: heads
        };

        this.http.post(api_url, form, httpOptions).subscribe(result => {
          resolve(result);
        }, error => {
          reject(error);
        });
      }
    });
  }


  async showLinkUrl(url) {

    await this.comService.showLoader();

    const options: InAppBrowserOptions = {
      clearcache: "yes",
      footer: "no",
      fullscreen: "yes",
      hardwareback: "yes",
      hidespinner: "no",
      presentationstyle: "pagesheet",
      toolbar: "no",
      hidden: "yes",
      closebuttoncaption: "Close",
      hidenavigationbuttons: "yes",
      hideurlbar: "yes",
      location: "yes"
    };

    const browser = this.iab.create(url, '_system', options);
    setTimeout(() => {
      this.comService.hideLoader();
    }, 1500);    
  }

  newWindow: any;
  openNewWindow(url) {
    this.newWindow = window.open('about:blank', '_system');
    this.newWindow.document.write(
      '<style> body{ background:#147b45;!important;  position: relative;} div.container { margin: 0;position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);color: white;font-family: arial;font-size: 1.5em; }</style>'
    );
    this.newWindow.document.write(
      "<body><div class='container'>LOADING...</div></body>"
    );
    this.newWindow.location = url;
    this.newWindow.focus();
    this.newWindow.addEventListener('close', () => {
      console.log('new window closed');
    });

    this.newWindow.addEventListener('hashchange', () => {

    });
  }

}
