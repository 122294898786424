export const slideOpts = {
  slidesPerView: 1,
  speed: 850,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  on: {
    beforeInit() {
      const swiper = this;

      swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
      swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

      swiper.params.watchSlidesProgress = true;
      swiper.originalParams.watchSlidesProgress = true;
    },
    setTranslate() {
      const swiper = this;
      const {
        width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
      } = swiper;
      const params = swiper.params.coverflowEffect;
      const isHorizontal = swiper.isHorizontal();
      const transform$$1 = swiper.translate;
      const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
      const rotate = isHorizontal ? params.rotate : -params.rotate;
      const translate = params.depth;
      // Each slide offset from center
      for (let i = 0, length = slides.length; i < length; i += 1) {
        const $slideEl = slides.eq(i);
        const slideSize = slidesSizesGrid[i];
        const slideOffset = $slideEl[0].swiperSlideOffset;
        const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;

         let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
        let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
        // var rotateZ = 0
        let translateZ = -translate * Math.abs(offsetMultiplier);

         let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
        let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;

         // Fix for ultra small values
        if (Math.abs(translateX) < 0.001) translateX = 0;
        if (Math.abs(translateY) < 0.001) translateY = 0;
        if (Math.abs(translateZ) < 0.001) translateZ = 0;
        if (Math.abs(rotateY) < 0.001) rotateY = 0;
        if (Math.abs(rotateX) < 0.001) rotateX = 0;

         const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

         $slideEl.transform(slideTransform);
        $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
        if (params.slideShadows) {
          // Set shadows
          let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
          let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
          if ($shadowBeforeEl.length === 0) {
            $shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
            $slideEl.append($shadowBeforeEl);
          }
          if ($shadowAfterEl.length === 0) {
            $shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
            $slideEl.append($shadowAfterEl);
          }
          if ($shadowBeforeEl.length) $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
          if ($shadowAfterEl.length) $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0;
        }
      }

       // Set correct perspective for IE10
      if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
        const ws = $wrapperEl[0].style;
        ws.perspectiveOrigin = `${center}px 50%`;
      }
    },
    setTransition(duration) {
      const swiper = this;
      swiper.slides
        .transition(duration)
        .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
        .transition(duration);
    }
  }
};

export const bannerSlideOption = {
  initialSlide: 0,
  slidesPerView: 1,
  speed: 850,
  grabCursor: true,
};

export const base_api_url = 'https://isocial.ai'; // 'https://23.100.16.133:8000';

export const server_url = 'https://isocial.ai/'; // 'https://23.100.16.133:8000/';


export const ACCESS_TOKEN = 'm_access_token';
export const ACCESS_TOKEN_TYPE = 'm_access_token_type';
export const USER_DATA = 'm_user_data';
export const SEEN_START = 'm_seen_start';
export const PUSH_TOKEN = 'm_push_token';
export const USER_LANG = 'm_user_lang';
export const THEME_TYPE = 'm_theme';


export class CartItemClass {
  id: string;
  name: string;
  image: string;
  category: string;
  price: number;
  discount_percent: number;
  ref: string;
  qty: number;
  iva: number;
  op1: string;
  op2: string;
  op3: string;
  op4: string;
  op5: string;
  items: ColorSize[];
  url: string;

  constructor(cart) {
    this.id = cart.id || '';
    this.name = cart.name || '';
    this.image = cart.image || '';
    this.category = cart.category || '';
    this.price = cart.price || 0;
    this.discount_percent = cart.discount_percent || 0;
    this.ref = cart.ref || '';
    this.url = cart.url || '';
    this.qty = cart.qty || 0;
    this.iva = cart.iva || 0;
    this.op1 = cart.op1 || '0';
    this.op2 = cart.op2 || '0';
    this.op3 = cart.op3 || '0';
    this.op4 = cart.op4 || '0';
    this.op5 = cart.op5 || '0';
    this.items = cart.items || [];
  }
}

export class ColorSize {
  color: string;
  size: string;
  qty: number;

  constructor(item) {
    this.color = item.color || '';
    this.size = item.size || '';
    this.qty = item.qty || 0;
  }
}


export class Address {
  id: string;
  nome_envio: string;
  contacto_envio: string;
  morada_envio: string;
  localidade_envio: string;
  postal_envio: string;
  pais_envio: string;
  is_default: string;
  is_shipping: string;
  country_name: string;
  checked: boolean;

  constructor(item) {
    this.id = item.id || '';
    this.nome_envio = item.nome_envio || '';
    this.contacto_envio = item.contacto_envio || '';
    this.morada_envio = item.morada_envio || '';
    this.localidade_envio = item.localidade_envio || '';
    this.postal_envio = item.postal_envio || '';
    this.pais_envio = item.pais_envio || '';
    this.is_default = item.is_default || '';
    this.is_shipping = item.is_shipping || '';
    this.country_name = item.country_name || '';
    this.checked = item.checked || false;
  }
}
