import { MyEvent } from './../../services/myevent.services';
import { CommonService } from './../../services/common.service';
import { CartItemClass, ColorSize } from './../../common/common';
import { PreferenceService } from './../../services/preference.service';
import { IonInput, NavController, Platform } from '@ionic/angular';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {

  // @ViewChild(IonInput) inputs: IonInput;

  @Input() item: any;
  @Input() gridVal: any = 2;

  quantity: any = 0;
  totalPrice: any;

  items: any[] = Array(1);
  showAllItems = false;

  constructor(
    private navCtrl : NavController,
    public preference: PreferenceService,
    private comService: CommonService,
    private myEvent: MyEvent,
    private platform: Platform,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslationService
  ) { }

  ngOnInit() {
    if (this.item?.color_size?.length > 0) {
      if (this.item?.color_size[0]?.subs?.length) {
        this.item.color_size[0].properties = [this.item?.color_size[0].subs[0]];
      }
    }
  }

  ngAfterViewInit() {
    if (!this.platform.is('ios')) {
      // this.inputs.getInputElement().then(input => {
      //   input.setAttribute('readonly', 'true');
      // });
    }
  }

  changeSize($event, subitem) {
    console.log('event == ', $event.target.value);
    console.log(subitem);
    const sSize = (subitem.ttp as Array<any>).filter(ee => ee.nome_opcao == $event.target.value);
    if (sSize.length) {
      subitem.op2 = sSize[0].op2;
    }
  }

  plus() {
    if (this.item.color_size.length) {
      if (this.item.color_size[0].subs.length) {
        this.item.color_size[0].properties = this.item.color_size[0].subs;
        this.showAllItems = true;
      }
    }
  }

  minute() {
    if (this.item.color_size.length) {
      if (this.item.color_size[0].subs.length) {
        this.item.color_size[0].properties = [this.item.color_size[0].subs[0]];
        for (let i = 1; i < this.item.color_size[0].subs.length; i ++) {
          this.item.color_size[0].subs[i].qty = null;
        }
        this.showAllItems = false;

        this.quantity = 0;
        (this.item.color_size[0].properties as Array<any>).forEach(itt => {
          if (itt.qty) {
            this.quantity = Number(this.quantity) + Number(itt.qty);
          }
        });
        // this.totalPrice = this.quantity ? (Number(this.item.promocao_desconto) > 0 ? Number(this.quantity) * Number(this.item.preco_ant) * (100 - Number(this.item.promocao_desconto)) / 100  : Number(this.quantity) * Number(this.item.preco)) : 0;
        this.totalPrice = this.quantity ? Number(this.quantity) * Number(this.item.preco) : 0;
      }
    }
  }

  showProd() {
    this.navCtrl.navigateForward(`product-detail/${this.item.id}`);
  }

  async addRmFav(item) {
    if (!this.comService.checkLogin()) {
      return;
    }

    await this.comService.showLoader('');
    this.api.apiPostFunction('favorites.php', {
      fv_insert: 'add_remove',
      prod_id: item.id
    }).then(result => {
      this.comService.hideLoader();
      console.log(result);
      if (result.status == 200) {
        item.is_favorite = result.is_favorite;
      } else {
        this.comService.showToast(result.message, 'error_toast');
      }
      this.cdr.detectChanges();
    }).catch(error => {
      this.comService.hideLoader();
      console.error('err = ', error);
    });
  }

  selectInput($event) {
    if (!this.platform.is('ios')) {
      const inpurEl = $event.target;
      inpurEl.removeAttribute('readonly');
    }
  }

  focusInput($event) {
    const inpurEl: IonInput = $event.target;
    inpurEl.placeholder = "";
  }

  leaveInput($event) {
    const inpurEl: IonInput = $event.target;
    inpurEl.placeholder = "0";
    if (!this.platform.is('ios')) {
      inpurEl.readonly = true;
      inpurEl.getInputElement().then(input => {
        input.setAttribute('readonly', 'true');
      });
    }
  }

  keyUp(keyCode) {
    if (keyCode == 13) {
      this.addToCart();
      if (!this.platform.is('ios')) {
        // this.inputs.getInputElement().then(input => {
        //   input.setAttribute('readonly', 'true');
        //   input.blur();
        // });
      }
    }
  }

  rmQty(item = null) {
    if (item) {
      if (!item.qty) {
        item.qty = 0;
      }
      if (item.qty > 0) {
        item.qty--;
      }
      this.calcTotlaPrice(item);
    } else {
      if (this.quantity > 0) {
        this.quantity--;
      }
      this.calcTotlaPrice();
    }
  }

  addQty(item = null) {
    if (item) {
      if (!item.qty) {
        item.qty = 0;
      }
      item.qty++;
      this.calcTotlaPrice(item);
    } else {
      this.quantity++;
      this.calcTotlaPrice();
    }
  }

  calcTotlaPrice(temp = null) {
    // if (temp) {
    //   temp.qty = $event.target.value as number;
    // } else {
    //   this.quantity = $event.target.value as number;
    // }

    if (this.item.color_size.length) {
      this.quantity = 0;
      (this.item.color_size[0].properties as Array<any>).forEach(itt => {
        if (itt.qty) {
          this.quantity = Number(this.quantity) + Number(itt.qty);
        }
      });
      // this.totalPrice = this.quantity ? (Number(this.item.promocao_desconto) > 0 ? Number(this.quantity) * Number(this.item.preco) * (100 - Number(this.item.promocao_desconto)) / 100  : Number(this.quantity) * Number(this.item.preco)) : 0;
      this.totalPrice = this.quantity ? Number(this.quantity) * Number(this.item.preco) : 0;
    } else {
      // this.totalPrice = this.quantity ? (Number(this.item.promocao_desconto) > 0 ? Number(this.quantity) * Number(this.item.preco) * (100 - Number(this.item.promocao_desconto)) / 100  : Number(this.quantity) * Number(this.item.preco)) : 0;
      this.totalPrice = this.quantity ? Number(this.quantity) * Number(this.item.preco) : 0;
    }
  }

  async addToCart() {
    if (this.item.nao_limitar_stock == '0' && this.item.stocks == 0) {
      this.comService.showToast(this.translateService.getTranslationWord('product_outstock'), 'error_toast');
      return;
    }

    if (!this.quantity || this.quantity == 0) {
      const input_quantity = await this.translateService.getTranslationWord('input_quantity');
      this.comService.showAlert(input_quantity);
      return;
    }

    const index = this.preference.myCarts.findIndex(iti => iti.id == this.item.id) > -1;
    if (index) {

      // const this_item_already_cart = await this.comService.getTranslationWord('this_item_already_cart');
      // this.comService.showToast(this_item_already_cart, 'error_toast');
      // return;

      this.updateInCart();

    } else {

      this.addNewCart();

    }
  }

  async updateInCart() {
    if (this.item.color_size.length) {
      if (this.item.color_size[0].nome_cat != '' && this.item.color_size[0].nome_cat2 == '') {
        (this.item.color_size[0].properties as Array<any>).forEach(itt => {
          if (itt.qty) {
            var cartItem = this.preference.myCarts.find(ele => ele.id == this.item.id && ele.category == this.item.categoria && ele.ref == this.item.ref && ele.op1 == itt.op1 && ele.op2 == itt.op2 && ele.op3 == itt.op3 && ele.op4 == itt.op4 && ele.op5 == itt.op5);
            if (cartItem) {
              cartItem.qty += Number(itt.qty);
              cartItem.items[0].qty += Number(itt.qty);
            } else {
              let cart: CartItemClass = new CartItemClass({});
              cart.id = this.item.id;
              cart.name = this.item.nome;
              cart.image = this.item.img_url + this.item.imagem1;
              cart.category = this.item.categoria;
              cart.price = Number(this.item.preco);
              cart.discount_percent = Number(this.item.promocao_desconto);
              cart.ref = this.item.ref;
              cart.url = this.item.url;
              cart.iva = Number(this.item.iva);
              cart.op1 = itt.op1;
              cart.op2 = itt.op2;
              cart.op3 = itt.op3;
              cart.op4 = itt.op4;
              cart.op5 = itt.op5;

              const temp: ColorSize = new ColorSize({
                color: itt.cor ? itt.nome_opcao : '',
                size: itt.cor ? '' : itt.nome_opcao,
                qty: Number(itt.qty)
              });
              cart.items = [temp];

              cart.qty = Number(itt.qty);
              this.preference.myCarts.push(cart);
            }

            setTimeout(() => {
              itt.qty = 0;
              this.calcTotlaPrice();
            }, 650);
          }
        });
      } else if (this.item.color_size[0].nome_cat != '' && this.item.color_size[0].nome_cat2 != '') {
        let itemCorrect = true;
        (this.item.color_size[0].properties as Array<any>).forEach(async (itt) => {
          if (itt.qty) {
            if (itt.ref == '') {
              itemCorrect = false;
              const select_size_of_color = await this.translateService.getTranslationWord('select_size_of_color');
              this.comService.showAlert(select_size_of_color + ' ' + itt.nome_opcao);
              return;
            }
          }
        });

        if (!itemCorrect) {
          return;
        } else {
          (this.item.color_size[0].properties as Array<any>).forEach(itt => {
            if (itt.qty) {
              if (itt.ref !== '') {
                var cartItem = this.preference.myCarts.find(ele => ele.id == this.item.id && ele.category == this.item.categoria && ele.ref == this.item.ref && ele.op1 == itt.op1 && ele.op2 == itt.op2 && ele.op3 == itt.op3 && ele.op4 == itt.op4 && ele.op5 == itt.op5);
                if (cartItem) {
                  cartItem.qty += Number(itt.qty);
                  cartItem.items[0].qty += Number(itt.qty);
                } else {
                  let cart: CartItemClass = new CartItemClass({});
                  cart.id = this.item.id;
                  cart.name = this.item.nome;
                  cart.image = this.item.img_url + this.item.imagem1;
                  cart.category = this.item.categoria;
                  cart.price = Number(this.item.preco);
                  cart.discount_percent = Number(this.item.promocao_desconto);
                  cart.ref = this.item.ref;
                  cart.url = this.item.url;
                  cart.iva = Number(this.item.iva);
                  cart.op1 = itt.op1;
                  cart.op2 = itt.op2;
                  cart.op3 = itt.op3;
                  cart.op4 = itt.op4;
                  cart.op5 = itt.op5;

                  const temp: ColorSize = new ColorSize({
                    color: itt.nome_opcao,
                    size: itt.ref,
                    qty: Number(itt.qty)
                  });
                  cart.items = [temp];

                  cart.qty = Number(itt.qty);
                  this.preference.myCarts.push(cart);
                }
              }

              setTimeout(() => {
                itt.qty = 0;
                this.calcTotlaPrice();
              }, 650);
            }
          });
        }
      }
    } else {
      var cartItem = this.preference.myCarts.find(ele => ele.id == this.item.id && ele.category == this.item.categoria && ele.ref == this.item.ref);
      cartItem.qty += Number(this.quantity);

      setTimeout(() => {
        this.quantity = 0;
        this.calcTotlaPrice();
      }, 650);
    }

    this.item.carted = true;
    this.myEvent.publishSomeData({event: 'update_cart'});
    console.log('cart == ', this.preference.myCarts);

    const added_cart = await this.translateService.getTranslationWord('added_cart');
    this.comService.showToast(added_cart, 'success_toast');
  }


  async addNewCart() {
    if (this.item.color_size.length) {
      if (this.item.color_size[0].nome_cat != '' && this.item.color_size[0].nome_cat2 == '') {
        (this.item.color_size[0].properties as Array<any>).forEach(itt => {
          if (itt.qty) {
            let cart: CartItemClass = new CartItemClass({});
            cart.id = this.item.id;
            cart.name = this.item.nome;
            cart.image = this.item.img_url + this.item.imagem1;
            cart.category = this.item.categoria;
            cart.price = Number(this.item.preco);
            cart.discount_percent = Number(this.item.promocao_desconto);
            cart.ref = this.item.ref;
            cart.url = this.item.url;
            cart.iva = Number(this.item.iva);
            cart.op1 = itt.op1;
            cart.op2 = itt.op2;
            cart.op3 = itt.op3;
            cart.op4 = itt.op4;
            cart.op5 = itt.op5;

            const temp: ColorSize = new ColorSize({
              color: itt.cor ? itt.nome_opcao : '',
              size: itt.cor ? '' : itt.nome_opcao,
              qty: Number(itt.qty)
            });
            cart.items = [temp];

            cart.qty = Number(itt.qty);
            this.preference.myCarts.push(cart);

            setTimeout(() => {
              itt.qty = 0;
              this.calcTotlaPrice();
            }, 650);
          }
        });
      } else if (this.item.color_size[0].nome_cat != '' && this.item.color_size[0].nome_cat2 != '') {
        let itemCorrect = true;
        (this.item.color_size[0].properties as Array<any>).forEach(async (itt) => {
          if (itt.qty) {
            if (itt.ref == '') {
              itemCorrect = false;
              const select_size_of_color = await this.translateService.getTranslationWord('select_size_of_color');
              this.comService.showAlert(select_size_of_color + ' ' + itt.nome_opcao);
              return;
            }
          }
        });

        if (!itemCorrect) {
          return;
        } else {
          (this.item.color_size[0].properties as Array<any>).forEach(itt => {
            if (itt.qty) {
              if (itt.ref !== '') {
                let cart: CartItemClass = new CartItemClass({});
                cart.id = this.item.id;
                cart.name = this.item.nome;
                cart.image = this.item.img_url + this.item.imagem1;
                cart.category = this.item.categoria;
                cart.price = Number(this.item.preco);
                cart.discount_percent = Number(this.item.promocao_desconto);
                cart.ref = this.item.ref;
                cart.url = this.item.url;
                cart.iva = Number(this.item.iva);
                cart.op1 = itt.op1;
                cart.op2 = itt.op2;
                cart.op3 = itt.op3;
                cart.op4 = itt.op4;
                cart.op5 = itt.op5;

                const temp: ColorSize = new ColorSize({
                  color: itt.nome_opcao,
                  size: itt.ref,
                  qty: Number(itt.qty)
                });
                cart.items = [temp];

                cart.qty = Number(itt.qty);
                this.preference.myCarts.push(cart);
              }

              setTimeout(() => {
                itt.qty = 0;
                this.calcTotlaPrice();
              }, 650);
            }
          });
        }
      }
    } else {
      let cart: CartItemClass = new CartItemClass({});
      cart.id = this.item.id;
      cart.name = this.item.nome;
      cart.image = this.item.img_url + this.item.imagem1;
      cart.category = this.item.categoria;
      cart.price = Number(this.item.preco);
      cart.discount_percent = Number(this.item.promocao_desconto);
      cart.ref = this.item.ref;
      cart.url = this.item.url;
      cart.iva = Number(this.item.iva);
      cart.qty = Number(this.quantity);
      this.preference.myCarts.push(cart);

      setTimeout(() => {
        this.quantity = 0;
        this.calcTotlaPrice();
      }, 650);
    }

    this.item.carted = true;
    this.myEvent.publishSomeData({event: 'update_cart'});
    console.log('cart == ', this.preference.myCarts);

    const added_cart = await this.translateService.getTranslationWord('added_cart');
    this.comService.showToast(added_cart, 'success_toast');
  }

}
