import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs'; // For rxjs 6

@Injectable({
    providedIn: 'root'
})
export class MyEvent {

  private selectedLanguage = new Subject<any>();
  private fooSubject = new Subject<any>();

  searchSub: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  public getLanguageObservable(): Observable<any> {
    return this.selectedLanguage.asObservable();
  }

  public setLanguageData(data) {
    this.selectedLanguage.next(data);
  }

  public publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  public getObservable(): Subject<any> {
    return this.fooSubject;
  }

  searching(searchTxt) {
    this.searchSub.next(searchTxt);
  }
}
