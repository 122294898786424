// ENGLISH
export const locale = {
  lang: 'en',
  data: {
    LOGIN: {
      TITLE: 'Welcome back',
      EMAIL_LABEL: 'Email address',
      PASSWORD_LABEL: 'Password',
      SUBMIT_BUTTON_TXT: 'Log in',
      DONT_HAVE_ACCOUNT: "Don't have an account?",
      SIGNUP_LINK_TXT: 'Sign up',
      ERRORS: {
        LOGIN_BAD_CREDENTIALS: 'Email or password is incorrect',
        LOGIN_USER_NOT_VERIFIED: 'Please verify your email address before login'
      }
    },
    SIGNUP: {
      TITLE: 'Create your account',
      DESCRIPTION: 'Please note that phone verification is required for signup. Your number will only be used to verify your identity for security purposes.',
      EMAIL_LABEL: 'Email address',
      PASSWORD_LABEL: 'Password',
      SUBMIT_BUTTON_TXT: 'Continue',
      ALREADY_HAVE_ACCOUNT: 'Already have an account?',
      LOGIN_LINK_TXT: 'Log in',
      ERROR: {
        REGISTER_INVALID_PASSWORD: "Password does not meet minimum criteria",
        REGISTER_USER_ALREADY_EXISTS: "The email is registered already"
      }
    },
    CHANGE_PASSWORD: {
      TITLE: 'Change your password',
      CURRENT_PASSWORD: 'Current Password',
      NEW_PASSWORD: 'New Password',
      CONFIRM_PASSWORD: 'Confirm the Password',
      SUBMIT_BUTTON_TXT: 'Save'
    },
    REQUEST_VERIFY: {
      TITLE: "Please verify your email",
      DESCRIPTION: "Didn't you get the link of your email verification?",
      BUTTON_TXT: 'Resend',
      ALREADY_VERIFIED: 'Already verified the email?',
      REFRESH_BUTTON_TXT: "Refresh",
      SUCCESS_MSG: "We sent activation link to your email",
      ERROR_MSG: "We could not send activation email. Please try again"
    },
    NEW_POST: {
      INVALID_URL: ""
    },
    EMAIL_VERIFY: {
      SUCCESS_TITLE: "SUCCESS!",
      DESCRIPTION: "Your email is verified successfully",
      BUTTON_TXT: 'Login',
      ERROR_TITLE: "Sorry, Verification Faild",
      ERROR_MSG: "Your token is not valid or expired. Please try again"
    },
    COMMON: {
      NO_BALANCE: "You don't have enough balance. Please purchase to get more tokens"
    }
	}
};
