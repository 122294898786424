import { server_url, CartItemClass, Address } from './../common/common';
import { Injectable } from '@angular/core';
import format from 'date-fns/format';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  currentUser: any;
  userSubscribe: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  notiSubscribe: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  image_url = server_url;
  pushToken = '';
  countries: any[] = [];

  myCarts: CartItemClass[] = [];
  totalPrice = 0;
  totalIVA  = 0;
  promoPrice = 0;
  promoVal = '';

  payments: any[] = [];

  defaultLang = 'pt';

  billingAddress: Address;
  shippingAddress: Address;

  admNoti: any;

  newNoti = 0;
  lat = 0;
  lng = 0;
  country: any;

  orange = 'assets/icon/orange.png';
  half = 'assets/icon/half.png';
  gray = 'assets/icon/gray.png';

  constructor() { }

  setCurrentUser(userData) {
    this.currentUser = userData;
    this.userSubscribe.next(userData);
  }

  setNoti(noti) {
    this.notiSubscribe.next(noti);
  }

  removeHtmlEntites(value: string) {
    if (!value || value === '') {
      return '';
    };

    const multiple = {
      '&nbsp;': ' ',
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
      '&quot;': '"',
      '&apos;': '\'',
      '&cent;': '¢',
      '&pound;': '£',
      '&yen;': '¥',
      '&euro;': '€',
      '&copy;': '©',
      '&reg;': '®',
      '&#160;': ' ',
      '&#60;': '<',
      '&#62;': '>',
      '&#38;': '&',
      '&#34;': '"',
      '&#39;': '\'',
      '&#162;': '¢',
      '&#163;': '£',
      '&#165;': '¥',
      '&#8364;': '€',
      '&#169;': '©',
      '&#174;': '®'
    };

    for (const char in multiple) {
      const before = char;
      const after = multiple[char];
      const pattern = new RegExp(before, 'g');
      value = value.replace(pattern, after);
    };

    return value;
  }
}
