import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { MyEvent } from 'src/app/services/myevent.services';
import { PreferenceService } from 'src/app/services/preference.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {

  @Input() showBack = false;
  @Input() showSearchButton = false;
  @Input() customBankFunc: any;

  showSearch = false;

  notiCount = 0;
  balance: any = 0;

  constructor(
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private myEvent: MyEvent,
    public pref: PreferenceService,
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    public comService: CommonService,
    private transService: TranslationService
  ) {
    this.myEvent.getObservable().subscribe(data => {
      if (data.event == 'purchase:success') {
        this.getNotis();
        this.getBalance();
      }
    });
  }

  ngOnInit() {
    this.pref.userSubscribe.subscribe(user => {
      if (user) {
        this.getNotis();
        this.getBalance();
      } else {
        this.notiCount = 0;
        this.balance = 0;
      }
      this.cdr.detectChanges();
    });
  }

  menuToggle() {
    this.menuCtrl.toggle();
  }

  getNotis() {
    this.api.apiGetFunction('auth/notifications/list').then(response => {
      console.log('noti resp == ', response);
      this.notiCount = response.length;
    }).catch(err => {
      console.error('noti err == ', err);
    });
  }

  getBalance() {
    this.api.apiPostFunction('auth/account/balance', {}, 'json', false).then(response => {
      console.log('balance resp == ', response);
      this.balance = response.balance;
      this.cdr.detectChanges();
    }).catch(async (err) => {
      console.error('balance err == ', err);
      var msg = await this.transService.getTranslationWord('COMMON.NO_BALANCE');
      this.comService.showToast(msg, 'error_toast');
    });
  }

  showNotification() {
    this.navCtrl.navigateForward('notifications');
  }

  hideSearch() {
    this.showSearch = false;
  }

  back() {
    if (this.customBankFunc) {
      this.customBankFunc();
    }
    else this.navCtrl.pop();
  }

  inputSearchTxt($event) {
    var searchIcon = document.querySelector('ion-searchbar');
    if (!!searchIcon) searchIcon.classList.add('custom_search');
    var $searchTxt = $event.target.value as string;
    this.myEvent.searching($searchTxt);
    if (!$searchTxt) {
      if (!!searchIcon) searchIcon.classList.remove('custom_search');
    }
  }

}
